import {
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { styles } from '../monitoringCustomStyles';

const InstanceRole = {
  primary: 'Primary',
  dr: 'DR Replica (Manual Failover)',
  failover_replica: 'HA Replica (Automatic Failover)',
  read_only_replica: 'Read Replica',
};

export function SelectInstance(props) {
  const { instance: selectedInstance, instances, setInstance } = props;

  return (
    <Stack width={'200px'}>
      <FormControl>
        <Select
          value={selectedInstance}
          onChange={(e) => {
            setInstance(e.target.value);
          }}
          sx={{
            padding: '0px 0px 0px 0px !important',
            background: styles.color.greyBackground4,
            '.MuiSelect-select': {
              padding: '8px 32px 8px 8px !important',
            },
          }}
          renderValue={(selectedValues) => {
            const selectedInstanceName = instances?.find(
              (instance) => instance?.id === selectedValues,
            )?.name;

            return (
              <Stack
                sx={{
                  flexDirection: 'row',
                  overflow: 'hidden',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    display: 'flex',
                    marginRight: '4px',
                    color: styles.color.monotoneDarker,
                  }}
                >
                  Instance:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    display: 'flex',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    fontWeight: '600',
                  }}
                >
                  {selectedInstanceName}
                </Typography>
              </Stack>
            );
          }}
        >
          {instances
            ?.filter((_i) => _i?.status !== 'DELETED')
            ?.map((instance) => {
              const role = InstanceRole[instance?.role?.toLowerCase()] || '';
              return (
                <MenuItem key={instance?.id} value={instance?.id}>
                  <ListItemText
                    sx={{
                      whiteSpace: 'initial',
                      maxWidth: '400px',
                    }}
                    primary={`${instance.name} (${role})`}
                  />
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Stack>
  );
}
