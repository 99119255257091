import { DF_METER } from 'Layouts/VerticalLayouts/AppUtil';
import moment from 'moment';

export const adaptDateForLeakFilter = (date: string) => {
  const _date = moment(date);
  return _date?.format(DF_METER) + 'Z';
};

export const getInitialStartTime = () => {
  const startTime = moment(new Date())?.subtract(1, 'month')?.toString();
  return adaptDateForLeakFilter(startTime);
};

export const getInitialEndTime = () => {
  const endTime = moment(new Date())?.toString();
  return adaptDateForLeakFilter(endTime);
};
