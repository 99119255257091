import { useState, useEffect } from 'react';
import { Stack, Typography, Chip, Link } from '@mui/material';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import { LoadingButton } from '@mui/lab';

import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';

type GetServiceNameByComputeProps = {
  open: boolean;
  setOpen: any;
};

export const GetServiceNameByCompute = (
  props: GetServiceNameByComputeProps,
) => {
  const { open, setOpen } = props;

  const [computeId, setComputeId] = useState('');
  const [dbs, setDbs] = useState([]);
  const { response, responseStatus, isLoading, postData } =
    useLazyGetCallWithURL();

  useEffect(() => {
    if (response?.response && responseStatus === 200) {
      setDbs(response?.response);
    }
  }, [response]);

  const onClose = () => {
    setComputeId('');
    setDbs([]);
    setOpen(false);
  };

  const DialogTitle = () => (
    <Typography>Get Service Name By Compute</Typography>
  );

  const DialogContent = () => {
    return (
      <Stack alignItems="center" justifyContent="center">
        <Stack>
          <TessellInput
            name="computeResourceId"
            label="Compute Resource Id"
            value={computeId}
            onChange={(e) => setComputeId(e.target.value)}
          />
          <LoadingButton
            loading={isLoading}
            variant="contained"
            disabled={!computeId?.length}
            sx={{ mt: 2 }}
            onClick={() => {
              postData(
                `${URLS.dBServiceByComputeIdURL}${computeId}/db-services`,
              );
            }}
          >
            Get Details
          </LoadingButton>
        </Stack>
        {dbs && (
          <Stack mt={2}>
            {dbs.map((db) => (
              <Stack direction="column">
                <Stack direction="row">
                  <Typography variant="h6">Service: </Typography>
                  <Typography variant="body1" m={0.7}>
                    <Link
                      href={`/database-services/details/${db?.tenantId}/${db?.pgSource}/${db?._id}/overview`}
                      target="_blank"
                    >
                      {db?.name}
                    </Link>
                  </Typography>
                </Stack>
                <Stack mt={1}>
                  <Typography variant="h6" m={0.7}>
                    Instances:
                  </Typography>

                  {db?.instances?.map((instance) => (
                    <Chip label={instance?.name} />
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>
    );
  };

  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
  };

  return (
    <TessellModal
      renderFunctions={renderFunctions}
      // width="350px"
      open={open}
      onClose={onClose}
    />
  );
};
