/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     9/7/2021     bakul.banthia         Created
 *
 */

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import {
  CloudIcon,
  ComputeIcon,
  DBGovernanceIcon,
  SLAIcon,
  UsersIcon,
} from 'assets/icons-v2';
import {
  ExpandMore,
  FileCopyOutlined,
  InfoOutlined,
} from '@mui/icons-material';
import { useContext, useEffect } from 'react';
import _includes from 'lodash/includes';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import { useStyles } from '../../utils';
import STATUS from '../../Status';
import { useFetchCloudLocations } from 'common/cloud-region-location/lib/component/useFetchCloudLocations';
import OverflowTooltip from 'common/overflow-tooltip/lib/components/OverflowTooltip';
import { LabelValue } from '../../LabelValue';
import { TruncatedLabelValue } from '../../TruncatedLabelValue';
import { DatabaseServiceType } from '../../../tenants-common-view/lib/schema/DatabaseService.types';
import { BYTES_TO_GB } from '../../constants/bytesToGB';
import { DEFAULT_NULL } from 'constants/appConstants';
import { useGetSubscriptionIdNameMap } from 'tools/useGetSubscriptionIdNameMap';
import { DF_HUMAN } from 'helpers/dateFormats';
import { CommonDataContext } from 'common/Contexts/CommonData';
import { capitaliseFirstLetterInEachWord } from 'helpers/capitaliseFirstLetterInEachWord';
import ComputeShapeInfo from './ComputeShapeInfo';
import { useServiceSummary } from './useServiceSummary';
import ParameterProfilesPopover from './ParameterProfilePopover';
import { TextWithInfoTooltip } from 'common/custom-components/lib/components/TextWithInfoTooltip';
import { ShowLabelValue } from 'common/custom-components/lib/components/ShowLabelValue';
import { serviceSummaryStyles } from './styles';
import { getMultiDiskStatus, getMultiServiceStatus } from './helpers';
import SLAPopover from './SLAPopover';
import { adaptSLATimingResponse } from './adaptSLAResponse';
import { useParams } from 'react-router-dom';

const numberFormatter = new Intl.NumberFormat();

interface ServiceSummaryProps {
  service: DatabaseServiceType;
}
const PRIMARY_ROLE = 'PRIMARY';

export const ServiceSummary = (props: ServiceSummaryProps) => {
  const params = useParams();

  const {
    databaseServiceId: entityId,
    tenantId,
    tenantDomain: pgSource,
  } = params;
  const { service: database } = props;
  const { regionMap } = useFetchCloudLocations();
  const classes = useStyles();
  const { findCompute } = useContext(CommonDataContext);

  const {
    ownerId: owner,
    subscriptionId,
    description,
    status,
    dateCreationCompleted,
    lastStartedAt,
    stoppedAt,
    infrastructureInfo,
    provisionInfo,
    computeSharingEnabled,
    serviceConfiguration,
    engineConfiguration,
    instances,
    availabilityMachineId,
  } = database;
  const {
    vpc: vpcName,
    encryptionKey,
    computeType,
    cloud,
    region,
    availabilityZone: azs,
    storage: totalStorage,
  } = infrastructureInfo.userView;

  const { subIdNameMap } = useGetSubscriptionIdNameMap({ tenantId });
  const { infrastructure = {} as any } = provisionInfo;
  const _softwareImageVersionInstace =
    instances?.filter((instance) => instance?.role === PRIMARY_ROLE)[0] || {};
  const { edition = '-' } = provisionInfo?.provisionGenericInfo || {};
  const { softwareImageVersion = '-' } =
    (_softwareImageVersionInstace as any)?.genericInfo || {};
  const { maintenanceWindow: maintenance } = serviceConfiguration;
  const { internalView } = engineConfiguration || {};
  const { parameterProfileId } = internalView || {};

  const {
    loggedInUserRole,
    myRoleIsLoading,
    parameterProfileName,
    parameters,
    parameterProfileIsLoading,
    expanded,
    setExpanded,
    computeDetails,
    setComputeDetails,
    anchorEl,
    handleClick,
    handleClose,
    isPopoverOpen,
    slaResponse,
    slaIsLoading,
    slaPopoverType,
  } = useServiceSummary({
    pgSource,
    tenantId,
    entityId,
    parameterProfileId,
    availabilityMachineId,
  });

  useEffect(() => {
    if (infrastructure?.awsInfraConfig) {
      setComputeDetails({
        name: computeType,
        memoryGB: (totalStorage / BYTES_TO_GB)?.toFixed(5),
        vcpus: infrastructure?.awsInfraConfig?.awsCpuOptions?.vcpus,
      });
    }
  }, [infrastructure]);

  if (Object.keys(database)?.length === 0) {
    return null;
  }

  const compute = findCompute(computeType) || { name: computeType };

  const handleChange = () => {
    setExpanded((currState) => !currState);
  };

  function GetSummaryHeader() {
    let computeText = 'NA';
    if (compute?.name) {
      const { name, profileInfo = {} } = (compute ||
        computeDetails ||
        {}) as any;
      const { vcpus, memoryGB } = (profileInfo || computeDetails || {}) as any;
      computeText = `${name} / ${vcpus} vCPUs / ${Number(memoryGB).toFixed(
        0,
      )} GiB Memory`;
      computeText += `/ ${Number(totalStorage / BYTES_TO_GB).toFixed(
        0,
      )} GiB Storage`;
    }

    let regionText = 'NA';
    if (cloud) {
      regionText = `${cloud?.toUpperCase()} / ${regionMap[region]}`;
    }

    if (expanded) {
      return <Typography sx={classes.boldText}>Summary</Typography>;
    }

    return (
      <Grid container direction="row" pb={0.3} alignItems="center">
        <Grid item xs={12} lg={4} xl={4} alignItems="center">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item sx={{ marginTop: 0.3 }}>
              <CloudIcon width={24} darkBg={false} />
            </Grid>
            <Grid item>
              <Typography pl={1} sx={classes.boldText}>
                Cloud:
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ paddingLeft: '16px' }}>{regionText}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8} xl={5} alignItems="center">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item sx={{ marginTop: 1 }}>
              <ComputeIcon width={24} darkBg={false} />
            </Grid>
            <Grid item>
              <Typography ml={1} sx={classes.boldText}>
                Compute:
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ paddingLeft: '16px' }}>
                {computeText}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} xl={3} alignItems="center">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item sx={{ marginTop: 0.5 }}>
              <UsersIcon width={30} darkBg={false} />
            </Grid>
            <Grid item>
              <Typography sx={classes.boldText}>Owner:</Typography>
            </Grid>
            <Grid item width="250px">
              <OverflowTooltip
                text={owner}
                placement="top"
                sxClass={{ ml: 1, color: 'textPrimary' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const GetSummary = () => {
    const { cloud = null, multiDisk = null } =
      infrastructureInfo?.userView || {};
    const isAzure = cloud?.toUpperCase() === 'AZURE';
    const { name: computeType = 'NA', profileInfo } = (compute ||
      computeDetails ||
      {}) as any;
    const { vcpus, memoryGB, readIops, writeIops, minStorageGB, storageGB } =
      (profileInfo || computeDetails || {}) as any;

    const getMaintenanceText = () => {
      const { day, duration, time } = maintenance;
      return `${day} ${time} for ${duration / 60} hours`;
    };

    const availabilityZoneText = Array.isArray(azs)
      ? azs.join(', ') || '-'
      : azs || '-';

    return (
      <Stack p={0.5} spacing={0.5}>
        {description && <Typography variant="body2">{description}</Typography>}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item lg={6} xl={2.5} md={6}>
            <Stack direction="row">
              <Box pt={0.3} pr={1}>
                <CloudIcon width={24} darkBg={false} />
              </Box>
              <Stack direction="column">
                <LabelValue
                  label="Cloud"
                  value={cloud?.toUpperCase() || 'NA'}
                />
                {region && (
                  <LabelValue label="Region" value={regionMap[region]} />
                )}
                <TruncatedLabelValue
                  label="Availability Zone(s)"
                  value={availabilityZoneText}
                />
                <LabelValue
                  label="Subscription"
                  value={subIdNameMap[subscriptionId] || '-'}
                />
                {vpcName && (
                  <LabelValue
                    label={isAzure ? 'VNet' : 'VPC'}
                    value={vpcName}
                  />
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item lg={6} xl={2.25} md={6}>
            <Stack direction="row">
              <Box pt={0.3} pr={1}>
                <ComputeIcon width={24} darkBg={false} />
              </Box>
              <Stack direction="column">
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <ShowLabelValue
                    label="Compute"
                    value={
                      <TextWithInfoTooltip
                        text={compute.name}
                        tooltipContent={
                          <ComputeShapeInfo
                            readIops={readIops}
                            writeIops={writeIops}
                          />
                        }
                        tooltipPopperProps={
                          serviceSummaryStyles.computeShapeTooltipPopperStyles
                        }
                      />
                    }
                  />
                </Stack>
                {vcpus && <LabelValue label="VCPUs" value={vcpus} />}
                {memoryGB && (
                  <LabelValue
                    label="Memory"
                    value={`${Number(memoryGB).toFixed(0)} GB`}
                  />
                )}
                {(minStorageGB || storageGB) && (
                  <LabelValue
                    label="Storage"
                    value={`${Number(totalStorage / BYTES_TO_GB).toFixed(
                      0,
                    )} GB`}
                  />
                )}
                {readIops && (
                  <LabelValue
                    label="Read IOPS"
                    value={numberFormatter.format(readIops)}
                  />
                )}
                {writeIops && (
                  <LabelValue
                    label="Write IOPS"
                    value={numberFormatter.format(writeIops)}
                  />
                )}

                <LabelValue
                  label="Multi-disk"
                  value={getMultiDiskStatus(multiDisk) || DEFAULT_NULL}
                />

                {encryptionKey && (
                  <TruncatedLabelValue
                    label="Encryption"
                    value={encryptionKey === null ? 'Disabled' : encryptionKey}
                  />
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item lg={6} xl={3.5} md={6}>
            <Stack direction="row">
              <Box pt={0.3} pr={1}>
                <DBGovernanceIcon width={24} darkBg={false} />
              </Box>
              <Stack direction="column">
                <LabelValue
                  label="Software Version"
                  value={softwareImageVersion || DEFAULT_NULL}
                />
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <LabelValue
                    label="Parameter Profile"
                    value={parameterProfileName}
                  />
                  <ParameterProfilesPopover
                    parameters={parameters}
                    isLoading={parameterProfileIsLoading}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    isPopoverOpen={isPopoverOpen && slaPopoverType === null}
                  />
                  {parameters.length !== 0 && (
                    <IconButton
                      sx={{ padding: 0 }}
                      onClick={(e) => {
                        handleClick(e);
                      }}
                    >
                      <InfoOutlined
                        color="primary"
                        style={{
                          fontSize: '20px',
                        }}
                      />
                    </IconButton>
                  )}
                </Stack>
                {maintenance && (
                  <LabelValue
                    label="Maintenance Window"
                    value={getMaintenanceText()}
                  />
                )}
                <LabelValue
                  label="Multi-service"
                  value={
                    getMultiServiceStatus(computeSharingEnabled) || DEFAULT_NULL
                  }
                />
                <LabelValue
                  label="Edition"
                  value={capitaliseFirstLetterInEachWord(edition) || '-'}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item lg={6} xl={3.75} md={6}>
            <Stack direction="row">
              <Box pt={0.3} pr={1}>
                <UsersIcon width={30} darkBg={false} />
              </Box>
              <Stack direction="column">
                <TruncatedLabelValue
                  label="Owner"
                  value={owner}
                  sxClass={{
                    width: '300px',
                    maxWidth: '300px',
                  }}
                  toolTipPlacement="top"
                />
                {loggedInUserRole && !myRoleIsLoading && (
                  <LabelValue
                    label="My Role"
                    value={capitaliseFirstLetterInEachWord(loggedInUserRole)}
                  />
                )}
                {dateCreationCompleted && (
                  <LabelValue
                    label="Date created"
                    value={moment(dateCreationCompleted).format(DF_HUMAN)}
                  />
                )}
                {lastStartedAt && status === STATUS.Ready && (
                  <LabelValue
                    label="Running since"
                    value={moment(lastStartedAt).format(DF_HUMAN)}
                  />
                )}

                {stoppedAt && status === STATUS.Stopped && (
                  <LabelValue
                    label="Stopped at"
                    value={moment(stoppedAt).format(DF_HUMAN)}
                  />
                )}
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <LabelValue label="TRN" value={entityId} />
                  <CopyToClipboard text={entityId}>
                    <Button color="primary" startIcon={<FileCopyOutlined />} />
                  </CopyToClipboard>
                </Stack>
              </Stack>
            </Stack>
          </Grid>

          {slaResponse?.scheduleInfo && (
            <Grid item lg={6} xl={3.75} md={6}>
              <Stack direction="row">
                <Box pt={0.3} pr={1}>
                  <SLAIcon width={30} darkBg={false} />
                </Box>
                <Stack direction="column">
                  <TruncatedLabelValue
                    label="SLA Details"
                    value={''}
                    sxClass={{
                      width: '300px',
                      maxWidth: '300px',
                    }}
                    toolTipPlacement="top"
                  />
                  {slaResponse?.scheduleInfo?.dailySchedule?.backupsPerDay && (
                    <TruncatedLabelValue
                      label="Daily Schedule"
                      value={`${
                        slaResponse?.scheduleInfo?.dailySchedule?.backupsPerDay
                      } backups per day at backup start times: ${slaResponse?.scheduleInfo?.dailySchedule?.backupStartTimes?.join(
                        ' ,',
                      )}`}
                    />
                  )}
                  <TruncatedLabelValue
                    label="Backup Start Time"
                    value={
                      adaptSLATimingResponse(
                        slaResponse?.scheduleInfo?.backupStartTime,
                      )
                        ? `${adaptSLATimingResponse(
                            slaResponse?.scheduleInfo?.backupStartTime,
                          )} (Asia/Calcutta)`
                        : '-'
                    }
                    sxClass={{
                      width: '300px',
                      maxWidth: '300px',
                    }}
                    toolTipPlacement="top"
                  />

                  {Array.isArray(
                    slaResponse?.scheduleInfo?.weeklySchedule?.weekDays,
                  ) && (
                    <TruncatedLabelValue
                      label="Weekly Schedule"
                      value={
                        slaResponse?.scheduleInfo?.weeklySchedule?.weekDays?.join(
                          ', ',
                        ) || ''
                      }
                    />
                  )}
                  {slaResponse?.scheduleInfo?.monthlySchedule?.commonSchedule &&
                    !slaResponse?.scheduleInfo?.monthlySchedule
                      ?.monthSpecificSchedule?.length && (
                      <TruncatedLabelValue
                        label="Monthly Schedule"
                        value={`${slaResponse?.scheduleInfo?.monthlySchedule?.commonSchedule?.dates?.join(
                          ' ,',
                        )} ${
                          slaResponse?.scheduleInfo?.monthlySchedule
                            ?.commonSchedule?.lastDayOfMonth
                            ? 'and last day of month'
                            : ''
                        }`}
                      />
                    )}
                  {slaResponse?.scheduleInfo?.monthlySchedule
                    ?.monthSpecificSchedule && (
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                    >
                      <LabelValue label="Monthly Schedule" value={''} />
                      {slaPopoverType === 'Monthly' && (
                        <SLAPopover
                          popoverType="Monthly"
                          anchorEl={anchorEl}
                          handleClose={handleClose}
                          isPopoverOpen={
                            isPopoverOpen && slaPopoverType !== null
                          }
                          data={slaResponse?.scheduleInfo?.monthlySchedule}
                        />
                      )}
                      {
                        <IconButton
                          sx={{ padding: 0 }}
                          onClick={(e) => {
                            handleClick(e, 'Monthly');
                          }}
                        >
                          <InfoOutlined
                            color="primary"
                            style={{
                              fontSize: '20px',
                            }}
                          />
                        </IconButton>
                      }
                    </Stack>
                  )}
                  {(slaResponse?.scheduleInfo?.yearlySchedule?.commonSchedule ||
                    slaResponse?.scheduleInfo?.yearlySchedule
                      ?.monthSpecificSchedule) && (
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                    >
                      <LabelValue label="Yearly Schedule" value={''} />
                      {slaPopoverType === 'Yearly' && (
                        <SLAPopover
                          popoverType="Yearly"
                          anchorEl={anchorEl}
                          handleClose={handleClose}
                          isPopoverOpen={
                            isPopoverOpen && slaPopoverType !== null
                          }
                          data={slaResponse?.scheduleInfo?.yearlySchedule}
                        />
                      )}
                      {
                        <IconButton
                          sx={{ padding: 0 }}
                          onClick={(e) => {
                            handleClick(e, 'Yearly');
                          }}
                        >
                          <InfoOutlined
                            color="primary"
                            style={{
                              fontSize: '20px',
                            }}
                          />
                        </IconButton>
                      }
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Stack>
    );
  };

  return (
    <>
      <Box sx={{ ...classes.accordionBox }} mt={1}>
        <Accordion
          disableGutters
          elevation={0}
          expanded={expanded}
          onChange={handleChange}
        >
          <AccordionSummary
            sx={classes.accordionSummaryBox}
            expandIcon={<ExpandMore />}
          >
            {GetSummaryHeader()}
          </AccordionSummary>
          <AccordionDetails>{GetSummary()}</AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
