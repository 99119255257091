import { useState, useMemo, useEffect } from 'react';
import { EXCEPTIONS_BASE_URL, URLS } from 'constants/URL';
import { useGetCall } from 'common/api/useApiCall';
import { adaptLeaksResponse } from './leaksAdapter';
import { useCreateExceptionJira } from '../Exceptions/useCreateExceptionJira';
import { useDataAndCount } from 'common/Hooks/useDataAndCount';
import { createParams } from 'helpers/createParams';
import { downloadData } from 'common/api/downloadFile';
import moment from 'moment';
import { DF_DOWNLOAD } from 'helpers/dateFormats';
import { COST_DEFAULT_DATE_RANGE } from 'customers/cost/constants';
import {
  adaptDateForLeakFilter,
  getInitialEndTime,
  getInitialStartTime,
} from './helper';

export const useLeaks = () => {
  const [detailsAnchorEl, setDetailsAnchorEl] = useState(null);
  const [range, setRange] = useState(COST_DEFAULT_DATE_RANGE);
  const [startTime, setStartTime] = useState(getInitialStartTime());
  const [endTime, setEndTime] = useState(getInitialEndTime());
  const [filters, setFilters] = useState({
    status: 'All',
    type: 'All',
    'tenant-name': 'All',
    'tenant-phase': 'LIVE',
    'leak-status': 'All',
    'start-time': startTime,
    'end-time': endTime,
  });

  const [selectedData, setSelectedData] = useState();
  const [typeFilter, setTypeFilter] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [selectedLeak, setSelectedLeak] = useState(null);
  const [openLeakStatusUpdateModal, setOpenLeakStatusUpdateModal] =
    useState(false);
  const [showCreateTicketModal, setShowCreateTicketModal] = useState(false);

  const { response: exceptionTypes } = useGetCall(
    `${URLS.fetchExceptionTypes}?is-leak=true`,
  );

  const { response: tenantNames } = useGetCall(URLS.fetchTenantsListLeaks);

  const leaksParams = useMemo(() => {
    return {
      'is-leak': true,
      ...filters,
      'start-time': startTime,
      'end-time': endTime,
    };
  }, [filters, startTime, endTime, range]);

  const {
    count: LeaksCount,
    pageCount,
    dataResponse: Leaks,
    dataIsLoading: leaksIsLoading,
    page,
    handlePaginationChange,
    fetchDataAndCount: fetchExceptionsAndCount,
    setPage,
  } = useDataAndCount({
    url: EXCEPTIONS_BASE_URL,
    dataResponseAdapter: adaptLeaksResponse,
    params: leaksParams,
  });

  const downloadLeaks = () => {
    downloadData(
      `${EXCEPTIONS_BASE_URL}/download-report?${createParams(leaksParams)}`,
      `LeakReport_${moment().format(DF_DOWNLOAD)}.csv`,
    );
  };

  useEffect(() => {
    if (tenantNames?.response?.length) {
      setTenants([
        { label: 'All', value: 'ALL' },
        ...(tenantNames?.response?.sort()?.map((type) => ({
          label: type,
          value: type,
        })) || []),
      ]);
    }
  }, [tenantNames]);

  const handleResetFilters = () => {
    setFilters({
      status: 'All',
      type: 'All',
      'tenant-name': 'All',
      'tenant-phase': 'LIVE',
      'leak-status': 'All',
      'start-time': startTime,
      'end-time': endTime,
    });
  };

  useEffect(() => {
    if (Array.isArray(exceptionTypes?.response)) {
      setTypeFilter([
        { name: 'All', id: 'All' },
        ...(exceptionTypes?.response.map((type) => ({
          name: type,
          id: type,
        })) || []),
      ]);
    }
  }, [exceptionTypes]);

  const handleOnSuccessStatusUpdate = () => {
    fetchExceptionsAndCount();
  };

  const handleFilterChange = (filterKey, val) => {
    setPage(1);
    setFilters((prev) => ({ ...prev, [filterKey]: val }));
  };

  const handleOnSuccess = () => {
    fetchExceptionsAndCount();
    resetTicketState();
    setShowCreateTicketModal(false);
  };

  const {
    createTicket,
    resetState: resetTicketState,
    ...createTicketProps
  } = useCreateExceptionJira({
    onSuccess: handleOnSuccess,
    exception: selectedLeak,
  });

  useEffect(() => {
    if (range === 'Custom') {
      return;
    }
    const et = moment(new Date());
    let st;
    switch (range) {
      case '1w':
        st = moment(new Date()).subtract(1, 'weeks');
        break;
      case '2w':
        st = moment(new Date()).subtract(2, 'weeks');
        break;
      case '3w':
        st = moment(new Date()).subtract(3, 'weeks');
        break;
      case '1m':
        st = moment(new Date()).subtract(1, 'month');
        break;
      default:
    }
    setStartTime(adaptDateForLeakFilter(st));
    setEndTime(adaptDateForLeakFilter(et?.toString()));
  }, [range]);

  const updateTimeUsingNativePicker = (start, end) => {
    setStartTime(adaptDateForLeakFilter(start));
    setEndTime(adaptDateForLeakFilter(end));
  };

  const handleDateToggle = (e, v) => {
    if (v) {
      setRange(v);
    }
  };

  return {
    page,
    Leaks,
    LeaksCount,
    typeFilter,
    filters,
    tenants,
    leaksIsLoading,
    pageCount,
    detailsAnchorEl,
    selectedData,
    selectedLeak,
    openLeakStatusUpdateModal,
    showCreateTicketModal,
    createTicketProps,
    startTime,
    endTime,
    range,
    handleDateToggle,
    updateTimeUsingNativePicker,
    setStartTime,
    setEndTime,
    createTicket,
    setSelectedLeak,
    setOpenLeakStatusUpdateModal,
    setShowCreateTicketModal,
    handleFilterChange,
    setSelectedData,
    setDetailsAnchorEl,
    handlePaginationChange,
    handleOnSuccessStatusUpdate,
    handleResetFilters,
    downloadLeaks,
  };
};
